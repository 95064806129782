body {
    margin: 0;
    font-size: 25px;
    font-family: Arial, Helvetica, sans-serif;
  }
nav a {
    color: #00008b;
}
.bg-white{
    padding: 0px;
    box-shadow: 0PX 0PX 8PX 0PX;
    
}
.bg-white a{
    font-weight: bolder;
    font-size: 30px;
}
.navbar .nav-link{
    padding: 2px 10px 2px 10px;
}

.container-floud .navbar{
    padding-bottom: 0px;
}
.content .card-title{
    color: #00008b;
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 0px;
}
ul li{
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}
.col-md-012 {
    object-fit: cover;
    width: 100% ;
    margin-bottom: 10px;
    height: 100%;
}
.col-md-012 img{
    width: 40%;
    height: 450px;
}
.carousel{
    margin-top: 20px;
}
.nav-item .nav-link i{
    padding: 0px 3px;
}
.collapse{
    justify-content: space-around;
}
/* .collapse .navbar-nav .nav-item .nav-link {
    border-bottom: 5px solid darkblue;
    font-size: 18px;
    color: white;
    margin: 6px 15px 0px 15px;
} */
/* .collapse .navbar-nav .nav-item .nav-link:hover{
    border-bottom: 5px solid white;
} */

.fa-bars{
    color: white;
}
.card-title .clients{
    justify-content: center;
    text-align: center;
}
.clients img{
    height: 40%;
    width: 40%;
    margin-left: 60px;
}
