
.Contact-us h4{
 font-size: 20px;
 font-weight: 800;
 color: darkblue;
}
.mb-4 {
    width: 100%;
    height: 350px;
    border: none;
}
.map{
    margin-top: 30px;
}
p{
    margin: 0px;
}
.card {
    text-align: center;
    border: none;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 20px;
}
.Contact-us h3 {
    margin: 15px 0 20px 0;
    font-size: 22px;
    font-weight: 700; 
    color: darkblue;
}
.card-title{
    padding: 10px;
    color: lightslategray;
    font-size: 15px;
}
.card-title i{
    font-size: 30px;
    width: 50px;
    height: 50px;
    color: #106eea;
    border-radius: 50%;
    padding: 8px;
    border: 2px dotted #b3d1fa;
}
.card-title-address {
    padding: 10px;
    color: lightslategray;
    font-size: 15px;
}
.card-title-address i{
    font-size: 30px;
    width: 50px;
    height: 50px;
    color: #106eea;
    border-radius: 50%;
    padding: 8px;
    border: 2px dotted #b3d1fa;
}
.card-title-address p{
  margin-top: 3px;
  margin-bottom: 2px;
}
button{
    background: #106eea;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}
h6{
    color: black;
    font-weight: 700;
}
