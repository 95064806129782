body {
  margin: 0;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
}
.fixed-top{
  text-decoration: none;
}
.item{
  font-weight: bolder;
  color: darkblue;
  text-decoration: none;
}
.fixed-top a {
  text-decoration: none;
}
.footer{
  text-align: center;
  margin: 10px 0px 0px 0px;
  background-color: darkblue;
}
.footer h5{
  padding: 10px 0px;
 color: white;
 margin: 0px;
}
.nav-link img{
  height: 8%;
  width: 8%;
}
img {
  margin-bottom: 5px;
  margin-top: 5px;
}