
.services h4{
    color: darkblue;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 800;
}
.row .col-md-3{
    margin-bottom: 10px;
}
.row .col-md-12{
    margin-top: 30px;
}
.row .col-md-12 h5{
    font-weight: 800;
    color: darkblue;
}
.row .col-md-12 p{
    font-size: 18px;
    font-family:Arial, Helvetica, sans-serif;
} 
.card-content{
    text-align: center;
}
.card-body{
    padding: 0px;
}
.card-content h6{
margin-top: 20px;
}
.card-content img {
    height: 70px;
    width: 101px;
}
img {
    max-width: 100%;
    max-height: 70%;
}

.portrait {
    height: 80px;
    width: 30px;
    text-align: center;
    justify-content: center;
}

.landscape {
    height: 30px;
    width: 80px;
}

.square {
    height: 75px;
    width: 75px;
}